.navigation-mode.notification-bar{
  z-index: 1001;
}

.notification-bar{
  .alert {
    top:50px;
  }
}
.notification-bar {
  z-index: zindex(action-modal);

  .alert {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 24px;
    margin: 0;
    border: 0;
    border-radius: 0;
  }

  .alert-icon {
    margin-right: 16px;
    display: block;
  }

  .alert-close {
    margin-left: auto;
    background: none;
    padding: 0;
    border: none;
    display: flex;
    align-items: center;
  }

  .alert-text {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
  }

  .alert-info {
    background-color: $info-dds;
  }

  .alert-warning {
    background-color: $warning-dds;
  }

  .alert-emergency {
    background-color: $error-dds;
  }

  .alert-success {
    background-color: $success-dds;
  }
}

.notifications-main {
  .column-widget {
    .generic-panel {
      .panel-body {
        .row {
          .col-md-12 {
            form {
              .row {
                .col-sm-1 {
                  flex: 0 0 5.33333%;
                }

                .sms-consent-group {
                  display: flex;
                  align-items: baseline;
                  margin: 10px 0 0 45px;

                  .sms-consent-message {
                    margin-left: 7px;
                  }

                  .sms-consent-check {
                    width: 21px;

                    i.fa {
                      font-size: 22px;
                      opacity: 67%;
                    }
                  }
                }

                .phone-notification {
                  display: flex;
                  margin-top: 26px;

                  .phone-notification-row1 {
                    margin-left: 29px;
                    width: 668px;
  
                    .row {
                      .powerselect-box {
                        width: 250px;
                      
                        .form-components-text {
                          width: 250px;
                        }

                        .ember-power-select-trigger {
                          padding: 0 7px 0 7px;
                          height: 27px;
                        }
                      }
                    }
                  }
                }


                .sms-notification {
                  display: flex;
                  margin-top: 26px;

                  .sms-notification-row2 {
                    margin-left: 29px;

                    .row {
                      .powerselect-box {
                        .form-components-text {
                          width: 250px;
                        }

                        .ember-power-select-trigger {
                          padding: 0 7px 0 7px;
                          height: 27px;
                        }
                      }
                    }

                    .powerselect-box {
                      width: 250px;
                    }
                  }

                  .sms-opt-in {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    margin: 0px 0 0 55px;
                    height: 75px;

                    .row-1 {
                      display: flex;
                      
                      .sms-consent-message {
                        margin-left: 27px;
                        width: 500px;

                        h6 {
                          line-height: 1.5;
                        }
                      }
                    }
                    
                    .row-2 {
                      display: flex;
                      align-items: baseline;
                      
                      .sms-send-opt-in-button {
                        display: flex;
                        align-items: baseline;

                        .btn {
                          background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
                          border: 1px solid #d4d6d7;
                          padding: 2px 11px 2px 11px;
                          font-size: 14px;
                          font-weight: 500;
                        }
                      }

                      .sms-opt-in-btn-message {
                        margin-left: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .notifications, .mail-notifications {
    margin-bottom: 13px !important;
  }
}

.mail-notifications-row {
  padding-left: 2.5rem;

  .ember-basic-dropdown {
    width: 500px;

    .mailNotification {
      .title {
        span {
          left: 97%;
          top: 2px;
        }
      }
    }
  }
}