// components/generic-widgets/side-drawer
// --- g-side-drawer ---

// side drawer layout
// header
$system-config-header-height: 40px;


// side drawer colors
$g-sidedrawer-header-icon-color: #A9ADB0;
$g-sidedrawer-font-color: $dds-primary-grey;
$g-sidedrawer-input-outline-color: #D4D6D7;
$g-sidedrawer-input-font-color: #334147;
$g-sidedrawer-button-color: dds-greys("900");
$g-sidedrawer-button-outline: $g-sidedrawer-button-color;

// side-drawer background color
$g-sidedrawer-background-color: white;
// side-drawer table colors
$g-sidedrawer-table-background-color: $background-lighter;
$g-sidedrawer-table-border-color: #CCCED0;

// side-drawer font
$g-sidedrawer-title-font-size: 15px;

// side-drawer background color
$g-sidedrawer-background-color: white;
// side-drawer table colors
$g-sidedrawer-table-background-color: $background-lighter;
$g-sidedrawer-table-border-color: #ccced0;

// side-drawer font
$g-sidedrawer-title-font-size: 15px;

.side-drawer-shrunken {
  width: 42px !important;
  min-width: 42px !important;
}

.g-side-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;

  background-color: $g-sidedrawer-background-color;
}

// components/generic-widgets/side-drawer/side-drawer-header
.g-side-drawer-header {
  height: $system-config-header-height;

  background-color: $g-sidedrawer-table-background-color;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  z-index: 10;

  .g-side-drawer-header-title {
    margin: 0 0 0 24px;
    font-size: $g-sidedrawer-title-font-size;
    font-weight: 600;
    white-space: nowrap;
  }

  .g-side-drawer-header-buttons {
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1.1;
    cursor: default;

    margin-right: 13px;

    .g-side-drawer-header-button {
      padding: 8px 0;
      margin-left: 12px;
      background-color: transparent;
      color: $icon-light-color;

      // borderless & don't draw a box around it when focused
      border: none;
      outline: none;

      cursor: pointer;

      &:hover {
        color: dds("700");
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      // e.g. highlight search button while search mode enabled
      &.mode-enabled {
        opacity: 0.75; // this is very dark with full opacity
        color: $icon-light-highlighted-color;

        &:hover {
          opacity: 1;
          color: dds("700");
        }
      }

      i {
        font-size: 1em;
      }
    }
  }
}

// components/generic-widgets/side-drawer/side-drawer-sub-header
.g-side-drawer-sub-header {
  height: 31px;
  padding: 0 24px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .4);

  font-size: 12px;
  font-weight: bold;
  line-height: 31px;

  z-index: 1;
}

// components/generic-widgets/side-drawer/side-drawer-body
.g-side-drawer-body {
  display: flex;
  flex-direction: column;

  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: calc(100% - 70px - 34px);

  overflow: hidden;
  overflow-y: auto;

  background-color: #F5F5F7;
  color: $font-color;
  font-family: $ui-font;

  &.no-padding {
    padding-top: 0;
  }
}

.g-side-drawer-scroll-container {
  overflow-y: scroll;
  height: 100px;
  margin-bottom: 12px;
}


// components/generic-widgets/side-drawer/side-drawer-panel
.g-side-drawer-panel {
  background-color: $g-sidedrawer-table-background-color;
  border: 1px solid $g-sidedrawer-table-border-color;

  margin: 0 8px 8px 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  .g-side-drawer-panel-header {
    display: flex;
    height: 36px;
    cursor: pointer;
    position:relative;
  }

  .g-side-drawer-panel-header-caret {
    flex: 0 0 30px;
    text-align: center;
    line-height: 36px;
  }

  .g-side-drawer-panel-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 36px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.is-collapsed {
    .g-side-drawer-panel-body {
      display: none;
    }
  }

  p {
    margin: 0 16px 18px 16px;
    font-size: 12px;
  }

  .g-side-drawer-panel-loader {
    display: flex;
    justify-content: center;
    align-items: center;

    // hack
    padding-top: 20px;
  }

  .g-side-drawer-panel-form {
    padding: 0 16px;
    border: 0;

    margin-bottom: 12px;
    margin-left: 15px;

    tr {
      &.is-hidden {
        display: none;
      }

      &.spacer>td {
        padding-bottom: 10px;
      }
    }

    tr {
      &.is-hidden {
        display: none;
      }
    }

    th {
      padding-right: 20px;

      font-size: 12px;
      text-align: right;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.left {
        text-align: left;
      }
    }

    &.compact {
      th {
        width: 120px;
        text-align: left;
        font-weight: normal;
      }
    }

    td {
      font-size: 12px;

      &.right-padded {
        padding-right: 8px;
      }
    }
  }

  .g-side-drawer-panel-table {
    font-size: 14px;
    border: none;

    thead {
      th {
        height: 24px;
        background-color: white;

        border-bottom: 2px solid $g-sidedrawer-table-background-color;
      }
    }

    tbody {
      max-height: 100px;
      overflow: scroll;
    }

    td {
      input[type=radio] {
        margin-right: 20px;
      }
    }

    tr {
      height: 24px;
    }

    td:first-of-type {
      padding-left: 10px;
    }

    td:last-of-type {
      padding-right: 10px;
    }

    tr:nth-child(odd) {
      td {
        background-color: white;
      }
    }
  }

  .ember-light-table {
    table {
      border: none;
      font-size: 14px;
    }

    .lt-head-wrap {
      padding-right: 0 !important;
    }

    .lt-head {
      th {
        height: 24px;
        background-color: white;

        border-bottom: 2px solid $g-sidedrawer-table-background-color;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .lt-body {
      background-color: white;
    }

    .lt-row {
      height: 23px;

      &:nth-child(even) {
        background-color: $g-sidedrawer-table-background-color;
      }

      &.is-selected {
        background-color: $table-row-selected-color !important;

        /* need to override striping for selected rows */
        &:nth-child(even) td {
          background: none;
        }
      }

      &.lt-is-loading, &.lt-no-data {
        height: 100%;

        td {
          height: 100%;
          background: none
        }
      }
    }

   .highlightable{
      color: #e02020;
    }
    .lt-cell {
      height: 24px;
      padding: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .needs-not-met {
        color: #E02020;
      }
    }

    // draw line for resize handle
    .lt-column-resizer {
      background: linear-gradient(to right,
                                  transparent 0%,
                                  transparent calc(50% - 1px),
                                  #ccc calc(50% - 1px),
                                  #ccc calc(50% + 0px),
                                  transparent calc(50% + 0px),
                                  transparent 100%) !important;
                                  height: 70%;
                                  margin-top: 4px;

    }
  }
}

.g-side-drawer-panel-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 12px 16px;

  h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 16px 0 0;
    white-space: nowrap;
  }

  .dropdown {
    width: 300px;
  }

  &.half {
    width: 50%;
  }
}

.g-side-drawer-summary {
  margin: 24px 24px 12px 24px;

  h5 {
    font-size: 14px;
    font-weight: bold;
  }

  p {
    font-size: 12px;
  }
}

.trip-transfer-impact-bar {
  width: 100%;
  padding: 6px 16px;
  display: flex;

  .dropdown {
    width: 120px;
    margin-right: 24px;
  }

  .impact {
    font-size: 14px;
    margin-left: 24px;
    display: inline-block;
  }

  .impact-text {
    font-weight: bold;
    margin-right: 8px;
  }
}

.trip-transfer-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  background-color: white;
  border-radius: 4px 4px 0 0;

  .divider {
    height: 16px;
    margin: 0 24px;
  }

  .tab {
    width: 50%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    color: #9B9B9B;
    padding-top: 3px;
    border-width: 0 0 3px 0;
    border-color: transparent;
    white-space: nowrap;
    -webkit-appearance: none;

    &.active {
      color: #586368;
      border-bottom-color: #586368;
    }
  }
}

// components/generic-widgets/side-drawer/side-drawer-actions
.g-side-drawer-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  padding: 20px;

  button {
    flex: 0 0 auto;
    font-size: 12px;

    line-height: 25px;
    min-width: 100px;
    height: 25px;

    border-radius: 3px;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .btn-side-drawer-cancel {
    margin-right: 8px;
  }

  .btn-side-drawer-submit {
    color: white;
    background-color: green;
  }
}

.g-side-drawer .ember-power-select-trigger[aria-disabled=true]  {
  background-color: transparent;
}

.g-side-drawer .error-message {
  color: #E02020;
  font-weight: bold;

  &.padded {
    padding: 0 0.75em;
  }
}

.redRow{
  .ETA{
    color: red;
  }
}
