.edit-trip-side-drawer {
  .g-side-drawer-body {
    background-color: white;
    margin-top: 10px;
    .g-side-drawer-panel .g-side-drawer-panel-title {
      font-size: 12px;
      font-weight: 600;
    }
    .form-widget-body{
      padding-right: 5px;
      padding-left: 5px;
      .form-edit-cell.address-search{
        .cell.button{
          height: 23px;
        }
      }
    }
    .cell.edit {
      min-width: 100px;
      font-size: 12px;
      flex: 1 1 30%;
    }
  }
}

.booking-trip-map-container {
  margin: 20px;
  padding: 0px;
  left: 477px!important;
  .booking-map-header {
    padding: 5px 20px;
  }
  #booking-map {
    width: 100%;
    height: 81.5vh;
  }


  .centered-scrolling-overlay {
    position: relative;
    min-height: 100vh;
    padding: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  /* basic modal style (an example, this is not necessary for the centering) */
  .centered-scrolling-container {
    position: relative;
    background-color: white;
    min-width: 60em;
    max-width: 650px;
    min-height: 30em;
    padding: 0;
    margin-top: 80px;
    margin-bottom: 30px;
    box-sizing: border-box;
    margin-left: 200px;
    box-shadow: 0px 4px 25px 4px rgba(0, 0, 0, 0.30);
  }
  .sp-header {
    -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  .sp-heading {
    padding: 3px 9px;
    font-weight: 500;
    font-size: 18px;
    background-color: #F6F6F6;
    border-radius: 7px 7px 0 0;
    .x-button {
      float: right;
      color: rgb(59, 57, 57);
    }
    .question-image {
      padding: 2px;
      float: right;
      color: #bdc5d4a8;
    }
  }

  .addressvalidationButton {
    margin-left: 300px !important;
    width: 100px !important;

  }

}
