.cancel-trip-form {
  height: calc(100vh - 35px - 55px);
  overflow-y: auto;

  .form-widget-header-wrapper {
    z-index: 5;
    .g-side-drawer-header {
      height: 35px;
    }
  }

  .form-widget-subheader {
    padding: 0.5rem 0 0 1.5rem;
    z-index: 5;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    .readonly-label {
      display: block;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .g-side-drawer-body {
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;

    font-size: 12px;
    .section-body {
      flex: 1 1 auto;
      margin-top: 16px;

      .cancel-trip-form-layout {
        .section-row {
          display: flex;
          align-items: center;
          margin: 0 0 0.5rem 0;
          padding-left: 10px;

          span {
            flex: 1 1 150px;
            white-space: nowrap;
          }

          .form-components-text {
            flex: 1 1 auto;
            padding: 0px 12px;
            width: 100%;

            input {
              &:read-only {
                background-color: transparent;
                border: transparent;
              }
            }

          }

          .form-components-select {
            flex: 1 1 auto;
            padding: 0px 12px;
            width: 100%;
          }

          .form-components-text-area {
            width: 100%;
            padding: 0px 12px;
            font-size: 12px;
            flex: 1 1 auto;
            textarea {
              flex: 1 0 auto;
              width: 100%;
            }
          }
        }

        .hint {
          text-align: right;
          color: #b8b8b8;
          float: left;
          width: 100%;
          font-size: 12px;
        }

        .form-row {
          justify-content: flex-end;
          margin: 10px;
          .btn {
            &.default {
              box-sizing: border-box;
              border: 1px solid #b4b4b4;
              border-radius: 2px;
              background: $secondary;
              box-shadow: 0 1px 2px 0 $secondary;
              color: #363b42;
              color: #000;

              &:hover {
                opacity: 0.75;
              }
            }

            &.success {
              box-sizing: border-box;
              border: 1px solid #b4b4b4;
              border-radius: 2px;
              background: $primary;
              box-shadow: 0 1px 2px 0 $secondary;
              color: #ffffff;
              &:hover {
                opacity: 0.75;
              }
            }
          }
        }
      }
    }
  }
}
