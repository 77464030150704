$table-light-text: #303e44;
$table-input-line-height: 28px;
$table-day-service-border-color: #e6e6e8;


.ivr-messages-view {
  .tab-wrapper {
    margin-top: 0.5rem;
    border: 1px solid #ccced0;
    border-radius: 4px;

    .tab-group {
      position: relative;
      @include box-shadow(0, 2px, 4px, rgba(199, 199, 199, 0.5));
    }

    .container-dds {
      background: $background-lighter;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          margin: 0;
        }
        .add-new-availability-btn {
          @include button();
          height: 22px;
          font-size: 12px;
          font-weight: 500;
    

          &:hover {
            color: white;
            background: $dds-primary;
            i {
              &:hover {
                color: white;
                background: $dds-primary;
              }
            }
          }
        }
      }
    }
  }

  .ivr-right-side-panel {
    margin: 15px;

    .interactive-voice-response-view, .display-only-values-view {
      .main-label {
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 12px 
      }

      .message-group {
        display: flex;
        margin: 0 10px 17px 0;

        label {
          width: 220px;
          font-size: 12px;
          font-weight: 600;
          margin: 0 100px 0 20px;
        }

        .form-components-text-area {
          .text-area-container {
            height: 60px;

            textarea {
              font-size: 13px;
              color: black;
            }
          }
        }

        .ivr-phone-number {
          &.error {
            div {
              border: 2px solid $otp-danger;
            }
          }
        }
      }
    }
  }
}
