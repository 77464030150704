.reoptimize-options {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 538px;
  height: 534px;
  border: 1px solid #CCCED0;
  border-radius: 4px;
  background-color: #F5F5F7;
  padding: 10px;

  .body-wrapper {
    display: flex;
    flex-direction: column;

    .reoptimize-providers-table {
      height: 357px !important;
      margin-bottom: 17px;
    }

    h3 {
      font-family: "Avenir Next";
      font-size: 14px;
      font-weight: 600;
    }
  
    .inputs {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .button-holder {
    display: flex;
    justify-content: flex-end;
    .reoptimize-start-button {
      @include button;
      height: 26px;
      width: 101px;
      border: 1px solid #B4B4B4;
      border-radius: 2px;
      background: linear-gradient(180deg, #F9F9F9 0%, #D1D1D1 100%);
      display: flex;
      justify-content: flex-end;
  
      span {
        flex: auto;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

}