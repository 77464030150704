.illegal-to-waitlist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 538px;
  height: 534px;
  border: 1px solid #CCCED0;
  border-radius: 4px;
  background-color: #F5F5F7;

  .illegal-to-waitlist-main {
    padding-top: 10px;
    padding-left: 10px;
  }
  
  button.illegal-to-waitlist-button {
    margin-right: 11px;
    margin-top: 5px;
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 450px !important;
    margin-bottom: 17px;

    h3 {
      font-family: "Avenir Next";
      font-size: 14px;
      font-weight: 600;
    }
  }
}