// --- Secondary Window (Modal Dialog) Component ---
// secondary window background color
$modal-dialog-header-bg: $background-lighter;
// secondary window box shadow
$modal-dialog-shadow: $box-shadow-color;

.secondary-window {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  font-family: $ui-font;
  background-color: rgba(0, 0, 0, 0.10);
  z-index: zindex(modal);

  .secondary-window-modal {
    @include box-shadow();
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 80vw;
    max-height: calc(80vw - #{$nav-height});
    height: 85%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    border-radius: 5px;
    background-color: white;

    @include desktop {
      height: 85%;
    }

    @include tablet {
      height: 70%;
    }

  }

  .secondary-window-header {
    flex: 0 0 40px;
    background-image: linear-gradient(#334247, #171F22);
    color: white;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: bold;
    padding-left: 41px;
    z-index: 1;
    @include box-shadow(0, 4px, 4px, $box-shadow);

    .spacer {
      flex: 1 1 1px;
    }

    .close-button {
      background: none;
      border: none;
      color: $widget-button-color;

      &:hover {
        color: $button-highlighted-color;
      }

      &:focus {
        outline: none;
        color: $widget-button-focused-color;
      }

      &:disabled {
        cursor: not-allowed;
        color: #AEAEAE;
      }
    }
  }

  .secondary-window-body {
    flex: 1 0 auto;
    height: 90%;
    display: flex;
    flex-direction: column;
    background-color: #263238;
    color: white;

    @include desktop {
      height: 90%;
    }

    @include tablet {
      height: 90%;
    }

    .loading-spinner {
      flex: 1 0 100px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .confirmBtn:hover {
      background-image:none;
      background-color: #7CB342;
    }
    .cancelBtn:hover{
      background-image: none;
      background-color: #6c757d;
    }
  }
}
