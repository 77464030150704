.add-new-route-side-drawer {
  .assigned-zone-header-label {
    margin-left: 15px;
    font-size: 12px;
    font-weight: bold;
  }

  .g-side-drawer-panel .ember-light-table .lt-row:nth-child(even) {
    background-color: white;
  }
  .table-component .table-component-body .ember-light-table .lt-body{
    font-size: 10px;
  }

  .table-component .table-component-header .table-component-title {
    font-size: 12px;
  }
  .table-component .table-component-body .ember-light-table .lt-head{
    font-size: 12px;
  }

  .g-side-drawer-body {
    padding-top: 8px;
    background-color: white;

    .g-side-drawer-panel {
      height: 100%;

      .g-side-drawer-panel-body {
        margin-top: 10px;

        .g-side-drawer-panel-form {
          width: 427px;
        }
      }
    }
  }

  .container {
    background: $background-lighter;
    display: flex;
  
    .zone-table {
      width: 260px;
    }

    .zone-button-wrapper {
      margin-left: 1.5rem;
      flex: 1 0 13%;
      button {
        @include button();
        height: 25px;
        border: 1px solid #B4B4B4;
        border-radius: 2px;
        background: $button-bg-grey;
        line-height: 1.1;
        cursor: pointer;

        span {
          flex: auto;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .input-group {
        display: flex;
        flex-direction: column;
        // flex-wrap: nowrap;
        // align-items: baseline;

        label {
          font-size: 12px;
          flex: 0 0 40%;
          color: $dark-dds;
        }

        .ember-power-select-trigger {
          flex: 0 0 50%;
        }

      }
    }
  }
}
.schedule-detail-info {
  display: flex;
  flex-direction: column;

  .schedule-title {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
}