#change-password {
  margin: 0 auto;
  width: 50%;
  max-width: 570px;
  font-family: $ui-font;

  h1 {
    margin-top: 100px;
    font-weight: 700;
  }

  form {
    padding-top: 1rem;
    border-top: 1px solid rgb(238, 238, 238);
    text-align: right;

    label {
      width: 100%;
      text-align: left;

      input[type=text],
      input[type=email],
      input[type=password] {
        display: block;
        width: 100%;
        padding: 3px 7px;
        line-height: 1.5em;
        color: $input-color;
        background-color: $input-bg;
        background-clip: padding-box;
        border: $input-border-width solid $input-border-color;
        border-radius: 3px;
        outline: none;
      }

      input[disabled] {
        background: #EFEFEF;
      }
    }

    .btn {
      line-height: 1.1;
      font-size: 15px;
      font-weight: 500;
      padding: 8px 15px;
      margin: 0px 5px;
      width: 85px;
      color: #000000;
      border: 2px solid #B3B3B3;
      opacity: 1;
    }

    .enabled-button {
      background-color: $button-bg-primary;
      color: $button-link-alt-color;
      border: 2px solid $button-bg-primary;
    }

    .password-criteria {
      border-radius: 3px;
      margin-top: 3em;
      padding: 20px 10px;
      list-style: none;
      text-align: left;
      border: 1px solid #cdd4d6;

      li {
        font-size: 15px;
        padding-bottom: 5px;

        div {
          display: flex;
          height: 22px;
          span {
            width: 20px;
          }
        }
      }
    }
  }
}
