.subscription-detail-overlay {
    z-index: 99;

    .subscription-detail-container {
        overflow-y: auto;
        max-height: 899px;
        width: 1000px;
        height: 426px;
      
        .subscription-message-notes {
            font-size: small;
            margin: 13px;
        }
    
        .subscription-bookings-body {
            border: solid 1px #d3d3d3c9;
            border-radius: 4px;
    
            .table-component-header {
                display: flex;
                align-items: center;
                margin: 0;
                height: 26px;
                background-color: #f5f5f7;
                border-bottom: solid 1px #d3d3d3c9;
                border-radius: 4px;
                h4.table-component-title.data-test-column-title {
                    flex-grow: 1;
                    flex-shrink: 0;
                    line-height: 16px;
                    margin: 0 0 4px 8px;
                    font-weight: 400;
                    font-size: 15px;
                }
            }
        
            .table-component-body{
                font-size: small;
                overflow-x: hidden;
                flex-basis: 150px;
                .column-controls {
                    visibility: hidden;
                }
                .lt-column-resizer {
                    display: none;
                }
    
                .lt-head-wrap {
                    background-color: #f5f5f7;
                    box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
                    z-index: 1;
                    overflow: visible;
                    position: sticky;
                    top: 0;
                }
                .ember-light-table {
                    .lt-body-wrap {
                        margin-top: 0px;
                        height: 229px;
                    }
                    table {
                        border: 0px !important;
                        border-collapse: collapse;
                    }
        
                    .lt-row:nth-child(odd) {
                        background-color: rgba(230, 231, 235, 0.4);
                    }
                    .lt-row:nth-child(even) {
                    background-color: #fff;
                    }
        
                    .lt-body{
                        font-size: 12px;
    
                        .lt-row {
                            .lt-cell {
                                .table-check-box-cell {
                                    margin-left: 7px;
                                }
                            }
                        }
                    }
        
                    .lt-head{
                        font-size: 12px;
                        .table-check-box-column {
                            margin-left: 7px;
                        }
                    }
    
                    .lt-column {
                        font-weight: 500;
                    }
                }
            }
        }
    
        .subscription-details-wrapper {
            .sp-header {
                background-color: #F6F6F6;
                -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                -moz-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
              }
              .sp-heading {
                margin: 0 0 0 11px !important;
                height: 28px;
                font-weight: 500;
                font-size: 16px;
                background-color: #F6F6F6;
                border-radius: 7px;
                .x-button {
                  float: right;
                  color: rgb(59, 57, 57);
                }
                .question-image {
                  padding: 2px;
                  float: right;
                  color: #bdc5d4a8;
                }
            
              }
              .ember-light-table {
                table {
                  border: 2px $input-border-color;
                  border-radius: 3px;
                  border-spacing: 0;
                  border-collapse: separate;
                  min-width: 200px;
                  width: 100%;
                  vertical-align: middle;
                }
              }
              .container-fluid {
                .row {
                  .g-side-drawer-panel-form {
                    .ember-tooltip {
                      margin: 0px 0px 0px 54px !important;
                      max-width: 300px;
            
                      .tooltip-inner {
                        max-width: 300px;
                    }
                    }
                  }
                }
              }
    
            .bd-body {
                .row {
                .g-side-drawer-panel {
                    max-width: 100% !important;
        
                    .trip-details {
                    display: flex;
                    width: 820px;
                    margin-left: 20px;
                    margin-bottom: 10px;
        
                    .g-side-drawer-panel-form {
                        padding: 0;
                        width: 400px;
                    }
                    }
                }
                }
            }
        }
    
        .subscription-buttons {
            margin: 13px 6px 6px 6px;
            float: right;
            display: flex;
            justify-content: flex-end;
            .btn-secondary {
              color: #5b5b5b;
              background-color: #dfdfdf;
              border-color: #bab6b6;
              min-width: 90px;
              line-height: 15px;
              font-weight: 500;
              border-radius: 2px;
              margin-right: 20px;
            }
          }
    }
    
}


