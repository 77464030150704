.is-touch-device .column-widget {
  .column-widget-header {
    padding: $widget-header-mobile-padding;
    flex-basis: $widget-header-mobile-height;

    .column-widget-title {
      font-size: $widget-title-mobile-font-size;
    }

  }

  .column-widget-body {
    font-size: $widget-body-mobile-font-size;

    .ember-light-table {
      width: fit-content;
    }

    .lt-head-wrap {
      .lt-head {
        padding: 4px 8px 3px 6px;
      }
    }
  }

  .column-widget-header .column-widget-controls .column-widget-button {
    margin-top: -3px;
    font-size: 20px;
  }

  .column-widget-body .ember-light-table .lt-cell {
    padding: 8px 8px 8px 5px;
  }

  .column-widget-button {
    border: 1px solid #ccc !important;
    border-radius: 4px;
    padding: 2px 10px 2px 10px !important;
  }

  .column-widget-title {
    font-size: 16px !important;
    line-height: 31px !important;
  }

  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
  -webkit-user-select: none;
  user-select: none;
}

.column-widget {
  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  color: $font-color;
  font-family: $ui-font;

  &.stops-widget.is-reordering {
    display: flex;
    flex-direction: column;
    border-width: 3px;
    border-style: solid;
    border-color: #2874cb;
  }

  .column-widget-header {
    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;

    background-color: $table-header-background-color;

    .column-widget-left-scroll-button{
      display: flex;
      border: 0;
      background-color: transparent;
      padding: 3px 5px 0px 0px;
      color:grey;
    }

    .column-widget-right-scroll-button {
      display: flex;
      border: 0;
      background-color: transparent;
      padding: 3px 10px 0px 5px;
      color:grey;
    }

    .column-widget-filters-scroll {
      display: -webkit-box;
      width: 40%;
      height: 24px;
      overflow-x: auto;
      overflow-y: hidden;
      flex-grow: 1;
      flex-shrink: 1;

      &::-webkit-scrollbar{
        height: 0px;
        background: transparent;
      }

      .column-widget-filter {
        border-radius: 5px;
        border: 1px solid darkgrey;
        height: 23px;
        font-size: small;
        width: max-content;

        &.is-active {
          background-color: $dds-300;
        }

        .column-widget-filter-button {
          border-radius: 5px;
          border-style: hidden;
          background-color: inherit;
          padding-top: 2px;
          width: 15px;
          height: 17px;
          color: grey;
        }

        .column-widget-filter-action {
          border-radius: 5px;
          border-style: hidden;
          background-color: inherit;
          padding-top: 2px;
          color: grey;
          height: 17px;
        }
      }
    }

    .column-widget-title {
      flex-grow: 1;
      flex-shrink: 1;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      font-weight: 600;
      font-size: 16px;
      width: auto;
      min-width: 140px;

      i {
        margin-left: 5px;
      }
    }

    // the search box needs a little extra margin for focus border
    .column-widget-search-box {
      flex-grow: 1;
      flex-shrink: 0;
      height: $widget-header-height - (2 * $widget-header-padding) - 2px;
      margin: 1px 2px 1px $column-title-margin;
      padding: 3px 5px 3px 5px;
      max-width: 20em;
      border-radius: 5px;
      font-size: $widget-input-font-size;
      background-color: transparent;
      border: none;
      outline: none;
    }

    .column-widget-controls {
      flex-shrink: 0;
      // height: 26px;
      margin-right: $column-title-margin;
      margin-left: auto; // helpful if we need to change .column-widget-header's justify-content attribute
      cursor: default;

      .column-widget-traversal-button {
        accent-color: green;
        opacity: 58% !important;

        &:focus {
          accent-color: green;
          opacity: 58% !important;
        }
      }

      .column-widget-button {
        padding: 0 5px 0 5px;
        margin-right: 8px;
        display: inline-block;
        margin-top: -2px;
        font-size: 18px;
        background-color: inherit;
        border: none;
        color: $icon-light-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;

        &:last-child {
          margin-right: 0;
        }
        // height: 25px;

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;
        }

        &:hover {
          color: dds("700");
          transition: color 0.2s;
          opacity: 1;
        }
      }
      .active-button {
        color: $icon-light-highlighted-color;
        transition: color 0.2s;
      }
    }
  }

  .column-widget-body {
    // must set `flex-basis` or else light table will have incorrect height
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    overflow: auto;
    font-size: $widget-body-font-size;
    background-color: $table-background-color;

    .ember-light-table {
      overflow: visible;
      font-feature-settings: "tnum";
      width: fit-content;

      input[type="checkbox"] {
        font-size: 20px;
      }

      input[type="checkbox"]:focus{
        outline:  #63c915  auto 0.5px !important;
      }

      .lt-head-wrap {
        background-color: $table-column-header-background-color;
        /* box-shadow: 0px 0px 5px 0px $grid-color; */
        box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
        z-index: 1;
        overflow: visible;
        position: sticky;
        top: 0;
      }

      .lt-head {
        width: 100%;
        border-left: solid $table-header-left-border-width
          rgba(255, 255, 255, 0);

        th {
          padding: 2px 2px 2px 2px;;
          th {
            padding: 4px;
          }
          font-weight: 700;
          color: $font-color;
          opacity: 0.8;

          &:first-child {
            padding-left: 7px;
            input {
              float: left;
            }
          }
        }
        // draw line for resize handle
        .lt-column-resizer {
          background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #ccc calc(50% - 1px),
            #ccc calc(50% + 0px),
            transparent calc(50% + 0px),
            transparent 100%
          ) !important;
          height: 70%;
          margin-top: 4px;
        }
      }

      .lt-column {
        &:focus-within {
          background-color: $table-cell-selected-color;
        }
      }

      .otp-danger .highlightable {
        color: $otp-danger;
      }

      .otp-warning .highlightable {
        color: $otp-warning;
      }

      .lt-row {
        border-left: solid $table-row-left-border-width rgba(255, 255, 255, 0);

        &:nth-child(2n-1) {
          background-color: $table-row-alt-background-color;
        }

        &.is-selected {
          background-color: $table-row-selected-color !important;
        }

        &.is-locked {
          background-color: lightgrey !important;
          color: grey;
        }

        &.lt-is-loading {
          text-align: center;
          background-color: transparent;
        }

        &.lt-no-data {
          background-color: transparent;
        }

        &.drag-target-above {
          border-top: 15px solid #ccf;
        }

        &.drag-target-below {
          border-bottom: 15px solid #ccf;
        }

        &.drag-target {
          background-color: #ccf;
        }

        &.otp-warning {
          border-color: $otp-warning;
        }

        &.otp-danger {
          border-color: $otp-danger;
        }
      }

      .lt-cell {
        padding: 3px 5px 2px 2px; // same here: we just want the top cell to have padding
        &.priorityId {
          display: none;
        }

        &:focus-within {
          background-color: $table-cell-selected-color;
        }

        .lt-cell {
          padding: 0 0 0 0;
        }
      }

      .lt-body-wrap {
        margin-top: 3px;
      }

      .lt-body {
        color: $table-body-text;
        font-weight: 500;
        margin-top: 3px;
      }

      .is-sorted {
        .lt-sort-icon {
          visibility: visible;
        }
      }

      .lt-sort-icon {
        float: none;
        margin-left: auto;
        margin-right: 0;
        padding-left: 2px;
        // visibility: hidden;
        line-height: 20px;
      }
    }

    .column-widget-editable-cell {
      width: 100%;
    }

    .cell-text-extension {
      background-color: transparent;
      border: 0;
      padding: 0;
      color: #657175;
      font-weight: 300;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.cell-show-border {
      .ember-light-table {
        .lt-cell {
          border: 1px solid #586368 !important;
        }
      }
    }
  }

  .this-trigger {
    outline: none;
    position: absolute;
  }

  .loading-spinner {
    margin: auto;
  }
}

@include tablet {
  .mobile-view .vehicles-widget
    .column-widget-body
    .ember-light-table
    .lt-head
    .vehicle-widget-id-custom-class {
    width: 80px !important;
  }

  .mobile-view .vehicles-widget .column-widget-body .ember-light-table .name {
    width: 80px !important;
  }

  .mobile-view .vehicles-widget table tr .driverName,
  .mobile-view .vehicles-widget table tr .routeName,
  .mobile-view .vehicles-widget table tr .provider,
  .mobile-view .stops-widget table tr .routeName,
  .mobile-view .message-widget table tr .routeName {
    width: 220px !important;
  }

  .mobile-view .vehicles-widget table tr .vehicleStatus,
  .mobile-view .vehicles-widget table tr .driverId,
  .mobile-view .vehicles-widget table tr .otp,
  .mobile-view .vehicles-widget table tr .highlightable,
  .mobile-view .stops-widget table tr .type,
  .mobile-view .stops-widget table tr .tripId,
  .mobile-view .stops-widget table tr .riderId,
  .mobile-view .stops-widget table tr .BreakType,
  .mobile-view .message-widget table tr .vehicleId,
  .mobile-view .message-widget table tr .driverId {
    width: 120px !important;
  }

  .mobile-view .vehicles-widget table tr .gpsTimeStamp,
  .mobile-view .vehicles-widget table tr .lat,
  .mobile-view .vehicles-widget table tr .lng,
  .mobile-view .stops-widget table tr .arriveLat,
  .mobile-view .stops-widget table tr .arriveLng,
  .mobile-view .stops-widget table tr .departLat,
  .mobile-view .stops-widget table tr .departLng,
  .mobile-view .stops-widget table tr .city {
    width: 160px !important;
  }

  .mobile-view .stops-widget table tr .address {
    width: 300px !important;
  }

  .mobile-view .stops-widget table tr .address > div {
    white-space: normal;
  }

  .mobile-view .stops-widget table tr .ETA,
  .mobile-view .stops-widget table tr .highlightable,
  .mobile-view .stops-widget table tr .OTPstatus,
  .mobile-view .stops-widget table tr .status,
  .mobile-view .stops-widget table tr .otp,
  .mobile-view .message-widget table tr .status,
  .mobile-view .message-widget table tr .read {
    width: 100px !important;
  }

  .mobile-view .stops-widget table tr .riderLastName,
  .mobile-view .stops-widget table tr .riderFirstName,
  .mobile-view .stops-widget table tr .notes,
  .mobile-view .stops-widget table tr .odometer,
  .mobile-view .message-widget table tr .firstName,
  .mobile-view .message-widget table tr .lastName {
    width: 170px !important;
  }

  .mobile-view .stops-widget table tr .requestedTime,
  .mobile-view .stops-widget table tr .actualArrivalTime,
  .mobile-view .stops-widget table tr .actualDepartTime,
  .mobile-view .stops-widget table tr .date,
  .mobile-view .stops-widget table tr .promisedTime,
  .mobile-view .stops-widget table tr .plannedDepartTime,
  .mobile-view .stops-widget table tr .BreakStartTime,
  .mobile-view .stops-widget table tr .BreakEndTime,
  .mobile-view .message-widget table tr .time,
  .mobile-view .message-widget table tr .deliveredTime {
    width: 180px !important;
  }
}


img.svg-widget-help-icon {
  position: relative;
  left: 4px;
  top: -2px;
  height: 18px;
  content: url('/icons/help-icon.svg');

  &:hover {
    content: url('/icons/help-icon-hover.svg');
    transition: color 0.2s;
  }
}

.schedule-column-widget .ember-light-table tr th:first-child,
 .schedule-column-widget .ember-light-table tr td:first-child {
  display: none;
}
