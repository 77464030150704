.table-component {
  display: flex;
  flex-direction: column;
  padding: $widget-border-width 0 $widget-border-width $widget-border-width;
  color: $font-color;
  font-family: $ui-font;
  flex-grow: 1;

  overflow: hidden;

  border: 1px solid #ccced0;
  background-color: $table-background-color;
  border-radius: 5px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    outline: 1px $widget-outline-color dashed;
  }

  .search-input {
    display: flex;
    justify-content: space-between;
    align-content: baseline;
    padding-left: 12px;

    // the search box needs a little extra margin for focus border
    input {
      border: transparent;
      outline: none;
      flex: 1 0 auto;
      width: calc(100% - 30px) !important;
    }

    .table-component-button {
      font-size: 18px;
      background-color: inherit;
      border: none;
      color: $icon-light-color;
      opacity: 0.5;
      cursor: pointer;
      outline: none;

      &:last-child {
        margin-right: 0;
      }
      // height: 25px;

      &:focus {
        box-shadow: 0 0 1px 1px $table-cell-selected-color;
        border-radius: 4px;
        opacity: 1;
      }

      &:hover {
        color: dds("700");
        transition: color 0.2s;
        opacity: 1;
      }
    }
  }

  .table-component-header {
    flex-basis: $widget-header-height;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $widget-header-padding;
    overflow-y: hidden;
    display: flex;
    justify-content: space-between;

    background-color: $table-header-background-color;

    .table-component-title {
      flex-grow: 1;
      flex-shrink: 0;
      line-height: $widget-header-height - (2 * $widget-header-padding);
      margin: 0 0 0 $column-title-margin;
      font-weight: 600;
      font-size: $widget-title-font-size;

      i {
        margin-left: 5px;
      }
    }

    .table-component-controls {
      display: flex;
      justify-content: space-evenly;
      cursor: default;

      .table-component-button {
        padding: 0 10px 0 10px;
        font-size: 18px;
        background-color: inherit;
        border: none;
        color: $icon-light-color;
        opacity: 0.5;
        cursor: pointer;
        outline: none;
        align-self: center;

        &:last-child {
          margin-right: 0;
        }
        // height: 25px;

        &:focus {
          box-shadow: 0 0 1px 1px $table-cell-selected-color;
          border-radius: 4px;
          opacity: 1;
        }

        &:hover {
          color: dds("700");
          transition: color 0.2s;
          opacity: 1;
        }
      }
    }
  }

  .table-component-body {
    // must set `flex-basis` or else light table will have incorrect height
    flex-basis: 300px;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - #{$widget-border-width});
    overflow: auto;
    font-size: $widget-body-font-size;
    background-color: $table-background-color;

    .ember-light-table {
      overflow: visible;
      font-feature-settings: "tnum";
      width: fit-content;

      input[type="checkbox"] {
        font-size: 20px;
      }

      .lt-head-wrap {
        background-color: $table-column-header-background-color;
        /* box-shadow: 0px 0px 5px 0px $grid-color; */
        box-shadow: 0px 1px 5px rgba(38, 50, 56, 0.2);
        z-index: 1;
        overflow: visible;
        position: sticky;
        top: 0;
      }

      .lt-head {
        width: 100%;
        border-left: solid $table-header-left-border-width
          rgba(255, 255, 255, 0);

        th {
          padding: 2px 2px 2px 2px;
          th {
            padding: 4px;
          }
          font-weight: 700;
          color: $font-color;
          opacity: 0.8;

          &:first-child {
            padding-left: 7px;
            input {
              float: left;
            }
          }
        }
        .priorityId {
          display: none;
        }
        // draw line for resize handle
        .lt-column-resizer {
          background: linear-gradient(
            to right,
            transparent 0%,
            transparent calc(50% - 1px),
            #ccc calc(50% - 1px),
            #ccc calc(50% + 0px),
            transparent calc(50% + 0px),
            transparent 100%
          ) !important;
          height: 70%;
          margin-top: 4px;
        }
      }

      .lt-column {
        &:focus-within {
          background-color: none;
        }
      }

      .otp-danger .highlightable {
        color: $otp-danger;
      }

      .otp-warning .highlightable {
        color: $otp-warning;
      }

      .lt-row {
        border-left: solid $table-row-left-border-width rgba(255, 255, 255, 0);

        &:nth-child(2n-1) {
          background-color: $table-row-alt-background-color;
        }

        &.is-selected {
          background-color: $table-row-selected-color !important;
        }

        &.is-locked {
          background-color: lightgrey !important;
          color: grey;
        }

        &.lt-is-loading {
          text-align: center;
          background-color: transparent;
        }

        &.lt-no-data {
          background-color: transparent;
        }

        &.drag-target-above {
          border-top: 15px solid #ccf;
        }

        &.drag-target-below {
          border-bottom: 15px solid #ccf;
        }

        &.drag-target {
          background-color: #ccf;
        }

        &.otp-warning {
          border-color: $otp-warning;
        }

        &.otp-danger {
          border-color: $otp-danger;
        }
      }

      .lt-cell {
        padding: 3px 5px 2px 2px; // same here: we just want the top cell to have padding
        &.priorityId {
          display: none;
        }

        &:focus-within {
          background-color: $table-cell-selected-color;
        }

        .lt-cell {
          padding: 0 0 0 0;
        }
      }

      .lt-body-wrap {
        margin-top: 3px;
      }

      .lt-body {
        color: $table-body-text;
        font-weight: 500;
        margin-top: 3px;
      }

      .is-sorted {
        .lt-sort-icon {
          visibility: visible;
        }
      }

      .lt-sort-icon {
        float: none;
        margin-left: auto;
        margin-right: 0;
        padding-left: 2px;
        // visibility: hidden;
        line-height: 20px;
      }
    }

    .table-component-editable-cell {
      width: 100%;
    }

    .cell-text-extension {
      background-color: transparent;
      border: 0;
      padding: 0;
      color: #657175;
      font-weight: 300;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .this-trigger {
    outline: none;
    position: absolute;
  }

  .loading-spinner {
    margin: auto;
  }
}

// table action dropdown
.table-component-content {
  z-index: zindex(dropdown) !important;
  margin-top: -1px;
  box-shadow: 0px 0px 2px 0px $widget-dropdown-shadow;
  font-family: $ui-font;
  font-size: $widget-body-font-size;
  background-color: $background-light;
  color: black;

  hr {
    margin: 0;
    border-top-width: 2px;
  }

  div {
    padding: 5px 10px;

    &:hover {
      cursor: pointer;
      background-color: dds("700");
      color: white;
    }

    &[disabled] {
      background-color: $button-disabled-background;
      color: dds-greys("100");
    }
  }

  i {
    margin-right: 6px;
  }
}
