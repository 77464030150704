.trip-transfer-side-drawer, .schedulingTrip-transfer-side-drawer{
  height: inherit;

  .ember-light-table .lt-column .lt-sort-icon {
    padding: 3px 8px;
  }

  .trip-transfer-layout, .schedulingTrip-transfer-layout {
    width: auto;
    height: calc(100vh - #{$total-nav-height} - #{$side-drawer-header-height});
    overflow-y: auto;
    &.auto-reschedule {
     .g-side-drawer-panel-title{
       margin-left: 10px;
     }

     .g-side-drawer-body {
      .g-side-drawer-panel {
        .g-side-drawer-panel-body {
          .scheduling-trip-transfer {
            overflow-y: hidden !important;
            flex-basis: 233px !important;

            .ember-light-table {
              .lt-head-wrap {
                width: 97%;
              }
            }
         }
        }
      }
     }
    }
    .g-side-drawer-body {
      margin-top: 8px;
      background-color: white;

      .g-side-drawer-panel.is-collapsible.is-expanded, .g-side-drawer-panel.is-initial{
        .g-side-drawer-panel-body {
          display: flex;
          flex-direction: column;
          padding: 0px 0 0px 0px;
          color: #303E44;

          .solution-preview-table-wrapper {
            overflow: auto;
            flex-basis: 130px;
            flex-grow: 1;
            flex-shrink: 1;
            width: calc(100% - 0px);
            font-size: 12px;
            background-color: #fff;

            .ember-light-table {
              overflow: visible;
              font-feature-settings: "tnum";
              width: fit-content;
            }
            .yellow-table-row{
              background-color: #F0ED92 !important;
            }
          }

          .solution-preview-note {
            margin: 0 16px 11px 16px;
          }

          .view-impact-table-wrapper {
            overflow: auto;
            flex-basis: 200px;
            flex-grow: 1;
            flex-shrink: 1;
            width: calc(100% - 0px);
            font-size: 12px;
            background-color: #fff;

            .ember-light-table {
              overflow: visible;
              font-feature-settings: "tnum";
              width: fit-content;
            }
            .yellow-table-row{
              background-color: #F0ED92 !important;
            }
          }

          .g-side-drawer-panel-selector {           
            &.bulk-trip{
              float: left;
              width: 43%;
            }
            .override-label1 {
              width: 53px;
              margin: 0px 14px 0 14px;
            }
            .override-label2 {
              width: 66px;
              margin: 0px 14px 0 40px;
            }
            .override-label3 {
              width: 66px;
              margin: 0px 14px 0 40px;
            }
            .override-label4 {
              width: 66px;
              margin: 0px 20px 0 40px;
            }
            .dropdown {
              .ember-power-select-trigger {
                width: 155px;
              }
              .trip-transfer-pickup-time {
                width: 61px;
                margin-left: 15px;
              }
              .trip-transfer-dropoff-time {
                width: 61px;
                margin-left: 15px;
              }
            }
          }

          input#override {
            margin-left: 10px;
          }

          label {
            margin: 5px 0px 7px 9px;
            font-size: 14px;
            font-weight: bold;
          }
          input[type="checkbox"] {
            vertical-align: bottom;
            position: relative;
            top: -4px;
            overflow: hidden;
          }

          .provider-solution-preview {
            display: flex;
            margin: 7px;

            h5 {
              font-size: 14px;
              font-weight: bold;
              margin-left: 11px;
              line-height: 19px;
            }

            .dropdown-provider {
              margin-left: 8px;
              padding: 0px 7px 0 0;
              line-height: 1.5;
              width: 180px;
              height: 22px;
            }

            .provider-solution-button {
              text-align: center;
              text-decoration: none;
              // border: solid 1px rgba(38, 50, 56, 0.2);
              color: black;
              // background-color: white;
              vertical-align: middle;
              height: 21px;
              width: 75px;
              border: 1px solid #B4B4B4;
              border-radius: 2px;
              background: linear-gradient(180deg, #f9f9f9 0%, #d1d1d1 100%);
              margin-left: 20px;
              line-height: 1.1;
              cursor: pointer;
              font-size: small;
            }
          }
        }
      }

      .g-side-drawer-panel-form {
        table-layout: fixed;
      }
    }
     div.buttons{
      margin: 10px;
      display: flex;
      justify-content: space-around;
      .btn.schedule-btns{
        color: $btn-text-color;
        line-height: 9.6px;
        font-size: 14px;
        font-family: 'AvenirNext';
        border-color: $btn-border-color;
        background-image: $re-schedule-btn-bg-grey;
        border-radius: 2px;
      }
    }
  }
}
.bulk-transfer{
  hr{
    width: 94%;
    &:last-child{
      display: none;
    }
  }
}

.solution-preview-header{
  background-color: white;
  border-bottom: 1px solid lightgray;
}

.g-side-drawer-panel p.trip-transfer-label {
  margin-top: 15px;
}
.reconcile-widget-base .form-widget-body{
  height: 700px;
}


// Add this hidden element to fix tooltip could not hover on empty element.
.solution-table-base-cell::before {
  content: "x";
  visibility: hidden;
  font-size: 0px
}
